import classNames from 'classnames';
import Button from 'components/Button.jsx';
import DatePickerCaption from 'components/DatePickerCaption.jsx';
import Input from 'components/Input.jsx';
import TimePicker from 'components/TimePicker.jsx';
import { ButtonType, DateRangePresets, Range } from 'constants/enums.js';
import { arSA, de, enUS, es, sq } from 'date-fns/locale';
import translate from 'i18n-translations/translate.jsx';
import CalendarIcon from 'icons/Dashboard/CalendarIcon.jsx';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import {
	getDateDifference,
	getDateWithSeconds,
	getMonthDayDateFormat,
	getTimezoneAbbreviation,
	localTimeToTimezone,
} from 'infrastructure/helpers/dateHelper.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const DatePickerLocales = {
	'en-EN': enUS,
	sq: sq,
	'ar-AE': arSA,
	'de-DE': de,
	'es-ES': es,
};

const DateRangePicker = ({
	defaultFrom,
	defaultTo,
	handleRangeChange,
	selectedTimezone = null,
	maxDays = 0,
	disableFutureDays = true,
	keepDateRangeOpen = false,
	defaultPreset = DateRangePresets.CUSTOM_RANGE,
}) => {
	const intl = useIntl();
	const locale = useSelector(state => state.language.locale);
	const [selectedRange, setSelectedRange] = useState({ from: defaultFrom, to: defaultTo });
	const [areDatesShown, setAreDatesShown] = useState(false);
	const [dateRangeError, setDateRangeError] = useState('');
	const [activePreset, setActivePreset] = useState(defaultPreset.id);
	const [selectedPreset, setSelectedPreset] = useState(defaultPreset.id);
	const [rangePresets, setRangePresets] = useState([]);
	const [formattedDate, setFormattedDate] = useState(intl.formatMessage({ id: 'selectRange' }));
	const [today, setToday] = useState(new Date());
	const [fromMonth, setFromMonth] = useState(defaultFrom || today);
	const [toMonth, setToMonth] = useState(defaultTo || today);
	const presetsRef = useRef(null);
	const pickDatesRef = useRef(null);
	const disabledDays = disableFutureDays ? { after: today } : null;

	const generatePreset = preset => {
		let from = new Date(today);
		let to = new Date(today);
		let translationKey = 'today';
		let translationParams = null;
		switch (preset) {
			case DateRangePresets.YESTERDAY:
				from.setDate(from.getDate() - 1);
				to.setDate(to.getDate() - 1);
				translationKey = 'yesterday';
				break;
			case DateRangePresets.LAST_7_DAYS:
			case DateRangePresets.LAST_30_DAYS:
			case DateRangePresets.LAST_90_DAYS:
				from = getLastAmountOfDays(preset.value);
				to.setDate(to.getDate());
				translationKey = 'lastAmountOfDays';
				translationParams = { value: preset.value };
				break;
			case DateRangePresets.LAST_MONTH:
				from.setMonth(from.getMonth() - 1);
				from.setDate(1);
				to.setDate(0);
				translationKey = 'lastMonth';
				break;
			case DateRangePresets.LAST_YEAR:
				from.setFullYear(from.getFullYear() - 1);
				from.setMonth(0);
				from.setDate(1);
				to.setMonth(0);
				to.setDate(0);
				translationKey = 'lastYear';
				break;
			case DateRangePresets.MONTH_TO_DATE:
				from.setDate(1);
				translationKey = 'monthToDate';
				break;
			case DateRangePresets.QUARTER_TO_DATE:
				from = getQuarterStart(to);
				translationKey = 'quarterToDate';
				break;
			case DateRangePresets.YEAR_TO_DATE:
				from.setMonth(0);
				from.setDate(1);
				translationKey = 'yearToDate';
				break;
			case DateRangePresets.FIRST_QUARTER:
			case DateRangePresets.SECOND_QUARTER:
			case DateRangePresets.THIRD_QUARTER:
			case DateRangePresets.FOURTH_QUARTER:
				const quarterLabels = ['firstQuarterOf', 'secondQuarterOf', 'thirdQuarterOf', 'fourthQuarterOf'];
				const range = getQuarterRange(preset.value);
				from = range.from;
				to = range.to;
				translationKey = quarterLabels[range.yearQuarter];
				translationParams = { value: range.from.getFullYear() };
				break;
			case DateRangePresets.CUSTOM_RANGE:
				translationKey = 'customRange';
				break;
			default:
				break;
		}
		from.setHours(0, 0, 0, 0);
		to.setHours(23, 59, 59, 999);
		const span = getDateDifference(from, to);
		const label = intl.formatMessage({ id: translationKey }, translationParams);

		return {
			id: preset.id,
			from,
			to,
			span,
			label,
		};
	};

	useEffect(() => {
		if (defaultPreset.id !== DateRangePresets.CUSTOM_RANGE.id) {
			const { from, to, label } = generatePreset(defaultPreset);
			setSelectedRange({ from, to });
			setFormattedDate(label);
		} else if (defaultFrom && defaultTo) {
			setFormattedDate(`${getMonthDayDateFormat(defaultFrom)} - ${getMonthDayDateFormat(defaultTo)}`);
			setSelectedRange({ from: defaultFrom, to: defaultTo });
		}

		const presets = Object.values(DateRangePresets).map(preset => generatePreset(preset));
		setRangePresets(
			maxDays > 0 ? presets.filter(preset => preset.id === DateRangePresets.CUSTOM_RANGE.id || preset.span <= maxDays) : presets
		);
	}, []);

	useEffect(() => {
		setSelectedRange({ from: defaultFrom, to: defaultTo });
	}, [defaultFrom, defaultTo]);

	useEffect(() => {
		const localToday = new Date();
		setToday(selectedTimezone ? new Date(localTimeToTimezone(localToday, selectedTimezone.zone)) : localToday);
		setSelectedPreset(defaultPreset.id);
		const presetLabel = rangePresets.find(preset => preset.id === defaultPreset.id)?.label;
		if (presetLabel) {
			setFormattedDate(
				defaultPreset.id === DateRangePresets.CUSTOM_RANGE.id
					? `${getMonthDayDateFormat(defaultFrom)} - ${getMonthDayDateFormat(defaultTo)}`
					: presetLabel
			);
		}
	}, [selectedTimezone, defaultPreset, defaultFrom, defaultTo, rangePresets]);

	const handleRangeSelect = range => {
		setSelectedPreset(DateRangePresets.CUSTOM_RANGE.id);
		presetsRef.current.scrollTo({ left: 0, top: presetsRef.current.scrollHeight, behavior: 'smooth' });
		if (!range) {
			setSelectedRange({ from: null, to: null });
			return;
		}
		if (range.from && range.to) {
			range.to.setHours(23, 59, 59, 999);
		}
		setSelectedRange(range);
		setDateRangeError('');
	};

	useOutsideClick(pickDatesRef, () => {
		if (areDatesShown && !keepDateRangeOpen) {
			resetRangeSelection();
		}
	});

	const getLastAmountOfDays = days => {
		const startOfRange = new Date(today);
		startOfRange.setDate(startOfRange.getDate() - days);
		return startOfRange;
	};

	const getQuarterStart = date => {
		const quarter = Math.floor(date.getMonth() / 3);
		const quarterStart = new Date();
		quarterStart.setFullYear(today.getFullYear(), quarter * 3, 1);
		return quarterStart;
	};

	const getQuarterRange = quarter => {
		const quarterStart = getQuarterStart(today);
		quarterStart.setMonth(quarterStart.getMonth() - quarter * 3);
		const quarterEnd = new Date(quarterStart);
		quarterEnd.setMonth(quarterStart.getMonth() + 3);
		quarterEnd.setDate(0);
		const yearQuarter = quarterStart.getMonth() / 3;
		return { from: quarterStart, to: quarter === 0 ? today : quarterEnd, yearQuarter };
	};

	const handlePresetSelect = preset => {
		const { from, to } = preset;
		if (preset.id === DateRangePresets.CUSTOM_RANGE.id) {
			setSelectedPreset(preset.id);
			return;
		}
		setSelectedRange({ from, to });
		setFromMonth(from);
		setToMonth(to);
		setSelectedPreset(preset.id);
		setDateRangeError('');
	};

	const resetRangeSelection = () => {
		setAreDatesShown(false);
		setSelectedRange({ from: defaultFrom, to: defaultTo });
		setSelectedPreset(activePreset);
		setDateRangeError('');
	};

	const applyRangeSelection = () => {
		const { from, to } = selectedRange;
		if (!from || !to) {
			setDateRangeError(intl.formatMessage({ id: 'selectValidDateRange' }));
			return;
		}
		const diff = getDateDifference(from, to);
		if (diff < 0) {
			setDateRangeError(intl.formatMessage({ id: 'selectValidEndTime' }));
			return;
		}
		if (maxDays && Math.abs(diff) >= maxDays) {
			setDateRangeError(intl.formatMessage({ id: 'selectWithinDateRange' }, { value: maxDays }));
			return;
		}
		const foundPreset = Object.values(DateRangePresets).find(value => value.id === selectedPreset);
		handleRangeChange({ from, to, preset: foundPreset });
		setAreDatesShown(false);
		setActivePreset(selectedPreset);
		const presetLabel = rangePresets.find(preset => preset.id === selectedPreset).label;
		setFormattedDate(
			selectedPreset === DateRangePresets.CUSTOM_RANGE.id
				? `${getMonthDayDateFormat(from)} - ${getMonthDayDateFormat(to)}`
				: presetLabel
		);
	};

	const handleTimeChange = (range, value) => {
		if (value) {
			const { hours, minutes, seconds, dayPeriod } = value;
			const dateTime = new Date(range === Range.START ? selectedRange.from : selectedRange.to);
			dateTime.setHours(dayPeriod === 1 ? hours : hours + 12);
			dateTime.setMinutes(minutes);
			dateTime.setSeconds(seconds);
			setSelectedRange(prevState => ({ ...prevState, [Range.START === range ? 'from' : 'to']: dateTime }));
		}
	};

	const formatZoneAbbreviation = () =>
		selectedTimezone ? getTimezoneAbbreviation(selectedTimezone.zone) : getTimezoneAbbreviation(moment.tz.guess());

	return (
		<div ref={pickDatesRef} className={classNames('date-range position-relative', areDatesShown ? 'date-range--is-active' : '')}>
			<div
				className='date-range-placeholder'
				onClick={() => {
					setFromMonth(defaultFrom || today);
					setToMonth(defaultTo || today);
					setAreDatesShown(prevState => !prevState);
				}}>
				<CalendarIcon />
				<span>{formattedDate}</span>
			</div>
			<div className={classNames('InputFromTo', (keepDateRangeOpen || areDatesShown) && 'date-range-popup-wrapper')}>
				{(keepDateRangeOpen || areDatesShown) && (
					<>
						<div ref={presetsRef} className='date-range-presets-wrapper'>
							<ul>
								{rangePresets.map(preset => (
									<li
										key={preset.id}
										className={selectedPreset === preset.id ? 'active' : ''}
										onClick={() => handlePresetSelect(preset)}>
										{preset.label}
									</li>
								))}
							</ul>
						</div>
						<div className='date-range-calendar-wrapper'>
							<div>
								{selectedTimezone && (
									<p className='date-range-timezone-label'>
										{translate('hospitalTimeZone')}: <b>{selectedTimezone.label}</b>
									</p>
								)}
								<div className='date-range-calendar-content'>
									<DayPicker
										mode='range'
										selected={selectedRange}
										onSelect={handleRangeSelect}
										disabled={disabledDays}
										month={fromMonth}
										onMonthChange={setFromMonth}
										showOutsideDays={true}
										captionLayout='dropdown-buttons'
										fromYear={2010}
										toYear={today.getFullYear()}
										locale={DatePickerLocales[locale]}
										components={{
											Caption: DatePickerCaption,
										}}
									/>
									<DayPicker
										mode='range'
										selected={selectedRange}
										onSelect={handleRangeSelect}
										disabled={disabledDays}
										month={toMonth}
										onMonthChange={setToMonth}
										showOutsideDays={true}
										captionLayout='dropdown-buttons'
										fromYear={2010}
										toYear={today.getFullYear()}
										locale={DatePickerLocales[locale]}
										components={{
											Caption: DatePickerCaption,
										}}
									/>
								</div>
							</div>
							{selectedPreset === DateRangePresets.CUSTOM_RANGE.id && (
								<>
									<div className='date-range-custom-inputs'>
										<div>
											<label>{capitalizeFirstLetter(intl.formatMessage({ id: 'from' }))}</label>
											<Input
												type='text'
												value={
													selectedRange.from ? `${getDateWithSeconds(selectedRange.from)} (${formatZoneAbbreviation()})` : ''
												}
												placeholder={intl.formatMessage({ id: 'selectStartDate' })}
												disabled={true}
											/>
										</div>
										<div>
											<label>{capitalizeFirstLetter(intl.formatMessage({ id: 'to' }))}</label>
											<Input
												type='text'
												value={selectedRange.to ? `${getDateWithSeconds(selectedRange.to)} (${formatZoneAbbreviation()})` : ''}
												placeholder={intl.formatMessage({ id: 'selectEndDate' })}
												disabled={true}
											/>
										</div>
									</div>
									<div className='date-range-time-pickers'>
										<TimePicker
											key={Range.START}
											value={selectedRange.from}
											index={0}
											handleTimeChange={value => handleTimeChange(Range.START, value)}
										/>
										<TimePicker
											key={Range.END}
											value={selectedRange.to}
											index={1}
											handleTimeChange={value => handleTimeChange(Range.END, value)}
										/>
									</div>
								</>
							)}
							{dateRangeError && <div className='date-range-error'>{dateRangeError}</div>}
							<div className='date-range-popup-footer'>
								<Button variant='white' className='cancel-btn' text={translate('cancel')} onClick={resetRangeSelection} />
								<Button variant={ButtonType.SUBMIT} text={translate('apply')} onClick={applyRangeSelection} />
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default DateRangePicker;
